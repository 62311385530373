import { LegacyRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDocumentTitle, useMeasure } from '@uidotdev/usehooks';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Loader from '@components/Loader';
import Header from '@views/Header';
import { ReportsShowcase } from '@views/ReportsShowcase';
import { CfsScoreSection } from '@views/CfsScoreSection';
import { Features } from '@views/Features';
import { NeighbourComparisonMap } from '@components/NeighbourComparisonMap';
import { MainComparison } from '@views/MainComparison';
import { StoreClosingsCard } from '@components/StoreClosingsCard';
import { ChainDetails } from '@views/ChainDetails';
import LocalDemographics from '@views/LocalDemographics';
import { SectionTitle } from '@components/SectionTitle';
import FeaturesIcon from '@assets/icons/Features';
import delay from '@utils/delay';
import { AxiosController } from '@utils/axiosController';
import { LockBodyScroll } from '@components/LockBodyScroll';
import { AnalyticsCard } from '@components/AnalyticsCard';

import { PrintContentWrapperStyled } from './Report.styles';
import { ReportData } from './Report.types';
import { replaceUSwithZipcode } from '@utils/replaceUSwithZipcode';
import {useKeycloak} from "@react-keycloak/web";

export const Report = () => {
  const { keycloak } = useKeycloak();
  const { token } = keycloak;

  const { reportId } = useParams();
  const [reportData, setReportData] = useState<ReportData>();
  const [clientData, setClientData] = useState<any>();
  const [chainData, setChainData] = useState<any>();
  const [mainCompetitorData, setMainCompetitorData] = useState<any>();
  const [nearestCompetitorData, setNearestCompetitorData] = useState<any>();
  const [mainNeighborData, setMainNeighborData] = useState<any>();
  const [featuresData, setFeaturesData] = useState<any>();
  const [analyticsData, setAnalyticsData] = useState<any>();
  const analyticsDataLimit = 200;

  const [propertyData, setPropertyData] = useState<any>();
  const [loadingPropertyData, setLoadingPropertyData] = useState<boolean>(true);
  const { getById: getPropertyById } = AxiosController(setLoadingPropertyData);

  const [loading, setLoading] = useState<boolean>(true);
  const { getById } = AxiosController(setLoading);
  const { get } = AxiosController(setLoading);

  const navigate = useNavigate();

  const defaultPageTitle = `CFS Report - ${reportId}`;
  const [pageTitle, setPageTitle] = useState<string>(defaultPageTitle);
  const pdfFileName = `CFS-Report-${reportId}-${new Date().toISOString().split('.')[0].replaceAll(':', '-')}`;

  useDocumentTitle(pageTitle);

  /* Letter size: 1440px 1864px; ratio - 1.29(4) */
  const defaultPdfPageRatio = 1.2944444;
  const defaultPdfPageWidth = '1600px';
  const defaultPdfPageHeight = `${Number(defaultPdfPageWidth.replace('px', '')) * defaultPdfPageRatio}px`;
  const isMultiPagePdf = true;
  const renderContentDurationInMs = 3000;
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [printContentRef, printContentDimensions] = useMeasure<HTMLDivElement>();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const copyrightText =
    'Caps for Sale Property Reports are compiled based on data from a number of providers, including ChainXY, Experian, Main Street Reports, Placer.ai, Synergos Technologies, and The Boulder Group.';

  const CopyrightMessage = () => (
    <p className="text-[11px] leading-[14px] text-center text-light-neutralTextWeak mt-8">
      {copyrightText}
    </p>
  );

  useEffect(() => {
    if(!token){
      return
    }
    const fetchData = async () => {
      try {
        const report = await getById(`/reports`, reportId);
        setReportData(report?.item);

        const propertyId = report?.item.propertyId;
        const clientId = report?.item.clientId;

        const [property, client] = await Promise.all([
          getPropertyById(`/properties`, propertyId),
          getById(`/clients`, clientId),
        ]);

        setPropertyData(property?.item);
        setFeaturesData(property?.item.features);
        setClientData(client?.item);

        const mainCompetitorId = property?.item['Main Competitor id'];
        const nearestCompetitorId = property?.item['Nearest Competitor id'];
        const mainNeighborId = property?.item['Main Neighbor id'];

        const [mainCompetitor, nearestCompetitor, mainNeighbor] = await Promise.all([
          getById(`/properties`, mainCompetitorId),
          getById(`/properties`, nearestCompetitorId),
          getById(`/properties`, mainNeighborId),
        ]);

        setMainCompetitorData(mainCompetitor?.item);
        setNearestCompetitorData(nearestCompetitor?.item);
        setMainNeighborData(mainNeighbor?.item);

        const chain = getChainData(property?.item['chain'], property?.item['chains']);

        setChainData(chain);

        const analytics = await get(
          `/properties/${propertyId}/comparison?limit=${analyticsDataLimit}`,
        );

        let hasProperty = false;
        const preparedAnalyticsData = analytics?.items.map((dataItem: any) => {
          const statsFormated = dataItem.stats.map((stat: Record<string, string | number>) => {
            if (dataItem.category === 'main' && stat.entity_id === property?.item['entity_id']) {
              hasProperty = true;
            }

            return {
              ...stat,
              ...{ highlighted_entity_id: property?.item['entity_id'] },
            };
          });

          return {
            ...dataItem,
            stats: statsFormated,
          };
        });

        if (!hasProperty) {
          preparedAnalyticsData[0].stats.push({
            entity_id: property?.item['entity_id'],
            highlighted_entity_id: property?.item['entity_id'],
            [property?.item['Chain Top 1']]: property?.item[property?.item['Chain Top 1']],
            [`${property?.item['Chain Top 1']}_shap_value`]:
              property?.item[`${property?.item['Chain Top 1']}_shap_value`],
            [property?.item['Chain Top 2']]: property?.item[property?.item['Chain Top 2']],
            [`${property?.item['Chain Top 2']}_shap_value`]:
              property?.item[`${property?.item['Chain Top 2']}_shap_value`],
            [property?.item['Chain Top 3']]: property?.item[property?.item['Chain Top 3']],
            [`${property?.item['Chain Top 3']}_shap_value`]:
              property?.item[`${property?.item['Chain Top 3']}_shap_value`],
            [property?.item['Chain Top 4']]: property?.item[property?.item['Chain Top 4']],
            [`${property?.item['Chain Top 4']}_shap_value`]:
              property?.item[`${property?.item['Chain Top 4']}_shap_value`],
          });
        }

        setAnalyticsData(preparedAnalyticsData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getFeature = useMemo(
    () => (featureName: string) => {
      const feature = featuresData.filter(
        (item: Record<string, string | number>) => item.Feature === featureName,
      )[0];

      return feature;
    },
    [featuresData],
  );

  useEffect(() => {
    if (isPrinting) {
      setPageTitle(pdfFileName);
    } else {
      setPageTitle(defaultPageTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrinting]);

  // Intercept ctrl + p print
  useEffect(() => {
    const ctrlP = (e: KeyboardEvent) => e.ctrlKey && e.key === 'p';

    const handleCtrlP = (e: KeyboardEvent) => {
      if (ctrlP(e)) {
        e.preventDefault();
        handleDownloadReport();
      }
    };
    const ignoreCtrlP = (e: KeyboardEvent) => {
      if (ctrlP(e)) {
        e.preventDefault();
      }
    };
    window.addEventListener('keyup', ignoreCtrlP);
    window.addEventListener('keydown', handleCtrlP);

    return () => {
      window.removeEventListener('keyup', ignoreCtrlP);
      window.removeEventListener('keydown', handleCtrlP);
    };
  });

  const getPdfWidth = useCallback((): string => {
    return defaultPdfPageWidth;
  }, [defaultPdfPageWidth]);

  const getPdfHeight = useCallback((): string => {
    if (isMultiPagePdf) {
      return defaultPdfPageHeight;
    }

    return `${printContentDimensions.height ?? 0}px`;
  }, [defaultPdfPageHeight, isMultiPagePdf, printContentDimensions.height]);

  const getChainData = (chainName: string, chains: Record<string, string>[]) => {
    const chianData = chains.filter(
      (chain: Record<string, string>) => chain['Chain Name'] === chainName,
    )[0];

    return chianData;
  };

  const handleDownloadReport = async () => {
    setIsPrinting(true);

    await delay(renderContentDurationInMs);
    window.print();

    setIsPrinting(false);
  };

  const handleBackToReports = useCallback(() => {
    const clientId = reportData?.clientId;

    navigate(`/clients/${clientId}`);
  }, [navigate, reportData?.clientId]);

  return (
    <PrintContentWrapperStyled
      ref={printContentRef as unknown as LegacyRef<HTMLDivElement>}
      className={`flex flex-col min-h-screen ${isPrinting ? 'overflow-visible w-[' + defaultPdfPageWidth + ']' : ''}`}
      $width={getPdfWidth()}
      $height={getPdfHeight()}
    >
      {(loadingPropertyData || isPrinting) && (
        <div className="relative w-screen h-screen print:hidden">
          <LockBodyScroll />
          <Loader size="lg" />
          <div
            className={`absolute inset-0 ${isPrinting ? 'bg-[#012023]' : 'bg-light-neutralBackgroundBase'}`}
          ></div>
        </div>
      )}

      {!loadingPropertyData && (
        <>
          <Header
            backButtonHandler={handleBackToReports}
            className="print:hidden"
            onDownloadReport={handleDownloadReport}
          />
          <div className="px-5 py-4 grow bg-light-neutralBackground animate-fadein">
            <div className={`mx-auto ${isPrinting ? 'max-w-[1500px]' : 'max-w-[1440px]'}`}>
              <div
                className={`border rounded-lg border-light-neutralBorder print:border-0 ${isPrinting ? 'p-12' : 'p-5'}`}
              >
                {reportData && clientData && (
                  <ReportsShowcase
                    name={reportData['entityName']}
                    address={replaceUSwithZipcode(propertyData['address'], propertyData['zipcode'])}
                    company={clientData.company}
                    chain={propertyData['chain']}
                    longitude={propertyData['Longitude']}
                    latitude={propertyData['Latitude']}
                  />
                )}

                <Tabs
                  className="mt-8"
                  {...(isPrinting ? { forceRenderTabPanel: true } : {})}
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                >
                  <TabList className="list-none print:hidden">
                    {['Entity Insights', 'Comparison', 'Basic Info', 'Advanced Analytics'].map(
                      (item) => (
                        <Tab
                          key={item}
                          className="inline-block px-8 py-2 text-base list-none rounded-t-lg cursor-pointer focus-visible:outline-none"
                          selectedClassName="inline-block px-8 py-2 text-base list-none rounded-t-lg bg-light-neutralBackgroundBase font-semibold"
                        >
                          {item}
                        </Tab>
                      ),
                    )}
                  </TabList>

                  <TabPanel
                    className={`hidden print:block ${isPrinting ? '!block' : ''}`}
                    selectedClassName="!block"
                  >
                    <div className="flex flex-col gap-6 p-6 rounded-lg rounded-tl-none bg-light-neutralBackgroundBase">
                      {isPrinting && (
                        <div className="text-[32px] leading-10 font-semibold tracking-tighter">
                          Entity Insights
                        </div>
                      )}

                      <div className="flex flex-col gap-6">
                        <CfsScoreSection
                          data={{
                            'CFS Score': propertyData['CFS Score'],
                            'Median_CFS_Score': propertyData['Median_CFS_Score'],
                            'Prediction 2 Year': propertyData['Prediction 2 Year'],
                            'Prediction 3 Year': propertyData['Prediction 3 Year'],
                            'Prediction 4 Year': propertyData['Prediction 4 Year'],
                            'Prediction 5 Year': propertyData['Prediction 5 Year'],
                            'Chain Top 1': propertyData['Chain Top 1'],
                            'Chain Top 2': propertyData['Chain Top 2'],
                            'Chain Top 3': propertyData['Chain Top 3'],
                            'Chain Top 4': propertyData['Chain Top 4'],
                            'Factor 1': propertyData['Factor 1'],
                            'Factor 2': propertyData['Factor 2'],
                            'Factor 3': propertyData['Factor 3'],
                            'Factor 4': propertyData['Factor 4'],
                          }}
                        />
                      </div>

                      <Features
                        comparisonKeys={[
                          propertyData['Feature 1'],
                          propertyData['Feature 2'],
                          propertyData['Feature 3'],
                          propertyData['Feature 4'],
                          propertyData['Feature 5'],
                          propertyData['Feature 6'],
                          propertyData['Feature 7'],
                          propertyData['Feature 8'],
                          propertyData['Feature 9'],
                          propertyData['Feature 10'],
                        ]}
                        significances={[
                          propertyData['Significance 1'],
                          propertyData['Significance 2'],
                          propertyData['Significance 3'],
                          propertyData['Significance 4'],
                          propertyData['Significance 5'],
                          propertyData['Significance 6'],
                          propertyData['Significance 7'],
                          propertyData['Significance 8'],
                          propertyData['Significance 9'],
                          propertyData['Significance 10'],
                        ]}
                        propertyData={propertyData}
                        features={propertyData['features']}
                      />
                    </div>
                    {isPrinting && <CopyrightMessage />}
                  </TabPanel>

                  <TabPanel
                    className={`hidden print:block ${isMultiPagePdf ? 'print:break-before-page' : ''} ${isPrinting ? '!block' : ''}`}
                    selectedClassName="!block"
                  >
                    {mainCompetitorData && nearestCompetitorData && mainNeighborData && (
                      <div className="flex flex-col gap-6 p-6 rounded-lg bg-light-neutralBackgroundBase print:mt-20">
                        {isPrinting && (
                          <div className="text-[32px] leading-10 font-semibold tracking-tighter">
                            Comparison
                          </div>
                        )}

                        <NeighbourComparisonMap
                          data={{
                            yourProperty: {
                              name: propertyData['chain'],
                              longitude: propertyData['Longitude'],
                              latitude: propertyData['Latitude'],
                            },
                            mainCompetitor: {
                              name: mainCompetitorData['chain'],
                              longitude: mainCompetitorData['Longitude'],
                              latitude: mainCompetitorData['Latitude'],
                            },
                            nearestCompetitor: {
                              name: nearestCompetitorData['chain'],
                              longitude: nearestCompetitorData['Longitude'],
                              latitude: nearestCompetitorData['Latitude'],
                            },
                            mainNeighbor: {
                              name: mainNeighborData['chain'],
                              longitude: mainNeighborData['Longitude'],
                              latitude: mainNeighborData['Latitude'],
                            },
                          }}
                        />

                        <MainComparison
                          comparisonKeys={[
                            propertyData['Feature 1'],
                            propertyData['Feature 2'],
                            propertyData['Feature 3'],
                            propertyData['Feature 4'],
                            propertyData['Feature 5'],
                            propertyData['Feature 6'],
                            propertyData['Feature 7'],
                            propertyData['Feature 8'],
                            propertyData['Feature 9'],
                            propertyData['Feature 10'],
                          ]}
                          propertyData={propertyData}
                          mainNeighborData={mainNeighborData}
                          features={propertyData['features']}
                        />
                      </div>
                    )}
                    {isPrinting && <CopyrightMessage />}
                  </TabPanel>

                  <TabPanel
                    className={`hidden print:block ${isMultiPagePdf ? 'print:break-before-page' : ''} ${isPrinting ? '!block' : ''}`}
                    selectedClassName="!block"
                  >
                    <div className="p-6 rounded-lg bg-light-neutralBackgroundBase print:mt-20">
                      <div className="flex flex-col gap-6">
                        {isPrinting && (
                          <div className="text-[32px] leading-10 font-semibold tracking-tighter">
                            Basic Info
                          </div>
                        )}

                        <div className="grid grid-cols-2 gap-6">
                          <StoreClosingsCard
                            data={{
                              state: propertyData['state'],
                              '1-Year Closure Rate': propertyData['1-Year Closure Rate'],
                              '2-Year Closure Rate': propertyData['2-Year Closure Rate'],
                              '3-Year Closure Rate': propertyData['3-Year Closure Rate'],
                              '4-Year Closure Rate': propertyData['4-Year Closure Rate'],
                              '5-Year Closure Rate': propertyData['5-Year Closure Rate'],
                              '1-Year Closure Rate (National)':
                                propertyData['1-Year Closure Rate (National)'],
                              '2-Year Closure Rate (National)':
                                propertyData['2-Year Closure Rate (National)'],
                              '3-Year Closure Rate (National)':
                                propertyData['3-Year Closure Rate (National)'],
                              '4-Year Closure Rate (National)':
                                propertyData['4-Year Closure Rate (National)'],
                              '5-Year Closure Rate (National)':
                                propertyData['5-Year Closure Rate (National)'],
                            }}
                          />

                          {chainData && (
                            <ChainDetails
                              data={{
                                "Standard & Poor's Rating": chainData["Standard & Poor's Rating"],
                                "Moody's Rating": chainData["Moody's Rating"],
                                'Typical Lease Type': chainData['Typical Lease Type'],
                                'Typical Lease Term': chainData['Typical Lease Term'],
                                'Typical Lease Escalation': chainData['Typical Lease Escalation'],
                                'Average Rent': chainData['Average Rent'],
                                'Current Count (National)':
                                  propertyData['Current Count (National)'],
                                'Current Count (State)': propertyData['Current Count'],
                              }}
                            />
                          )}
                        </div>

                        <LocalDemographics
                          tableData={[
                            [
                              [
                                'Population',
                                propertyData['1 mile total population'],
                                propertyData['3 mile total population'],
                                propertyData['5 mile total population'],
                              ],
                              [
                                'Male',
                                propertyData['1 mile male population'],
                                propertyData['3 mile male population'],
                                propertyData['5 mile male population'],
                              ],
                              [
                                'Female',
                                propertyData['1 mile female population'],
                                propertyData['3 mile female population'],
                                propertyData['5 mile female population'],
                              ],
                              [
                                'Under 25',
                                propertyData['1 mile population under 25 years'],
                                propertyData['3 mile population under 25 years'],
                                propertyData['5 mile population under 25 years'],
                              ],
                              [
                                'Over 25',
                                propertyData['1 mile population over 25 years'],
                                propertyData['3 mile population over 25 years'],
                                propertyData['5 mile population over 25 years'],
                              ],
                            ],
                            [
                              'Number of Households',
                              propertyData['1 mile total number of households'],
                              propertyData['3 mile total number of households'],
                              propertyData['5 mile total number of households'],
                            ],
                            [
                              'Population Density',
                              propertyData['1 mile population density'],
                              propertyData['3 mile population density'],
                              propertyData['5 mile population density'],
                            ],
                          ]}
                          mapData={{
                            name: propertyData['chain'],
                            longitude: propertyData['Longitude'],
                            latitude: propertyData['Latitude'],
                            populationOneMile: propertyData['1 mile total population'],
                            populationThreeMiles: propertyData['3 mile total population'],
                            populationFiveMiles: propertyData['5 mile total population'],
                          }}
                        />
                      </div>
                    </div>
                    {isPrinting && <CopyrightMessage />}
                  </TabPanel>

                  <TabPanel
                    className={`hidden print:block ${isMultiPagePdf ? 'print:break-before-page' : ''} ${isPrinting ? '!block' : ''}`}
                    selectedClassName="!block"
                  >
                    <div className="p-6 rounded-lg bg-light-neutralBackgroundBase print:mt-20">
                      {isPrinting && (
                        <div className="text-[32px] leading-10 font-semibold tracking-tighter mb-4">
                          Advanced Analytics
                        </div>
                      )}

                      <div className="grid items-start grid-cols-2 gap-2">
                        <SectionTitle title={'Top Features Summary'} icon={<FeaturesIcon />} />

                        <p className="text-base leading-5 text-light-neutralTextWeak">
                          We create a summary plot showing the average impact of each feature on the
                          model's predictions across all instances in the dataset. This plot helps
                          us understand which features are most important overall in predicting
                          closure likelihood.
                        </p>
                      </div>

                      {analyticsData && (
                        <div className="flex flex-col gap-4 mt-[40px]">
                          {['Chain Top 1', 'Chain Top 2', 'Chain Top 3', 'Chain Top 4'].map(
                            (key) => {
                              const featureName = propertyData[key];
                              const feature = getFeature(featureName);

                              let minXScaleValue = feature['Min'];
                              let maxXScaleValue = feature['Max'];

                              if (featureName === 'Longitude') {
                                minXScaleValue = -180;
                                maxXScaleValue = 180;
                              }

                              if (featureName === 'Latitude') {
                                minXScaleValue = -90;
                                maxXScaleValue = 90;
                              }

                              return (
                                <AnalyticsCard
                                  key={key}
                                  data={analyticsData}
                                  dataKeys={{
                                    x: featureName,
                                    y: [`${featureName}_shap_value`],
                                  }}
                                  title={featureName}
                                  minXScaleValue={minXScaleValue}
                                  maxXScaleValue={maxXScaleValue}
                                  bottomAxisLabel={feature['Units']}
                                />
                              );
                            },
                          )}
                        </div>
                      )}
                    </div>
                    {isPrinting && <CopyrightMessage />}
                  </TabPanel>
                </Tabs>
              </div>

              <p className="text-[11px] leading-[14px] text-center text-light-neutralTextWeak mt-2 print:hidden">
                {copyrightText}
              </p>
            </div>
          </div>
        </>
      )}
    </PrintContentWrapperStyled>
  );
};

export default Report;
