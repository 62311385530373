import BarChartIcon from '@assets/icons/BarChart';
import { FactorsCard } from '@components/FactorsCard';
import { ProbabilityClosureCard } from '@components/ProbabilityClosureCard';
import { ProbabilityOpeningCard } from '@components/ProbabilityOpeningCard';
import { SectionTitle } from '@components/SectionTitle';

import { CfsScoreSectionProps } from './CfsScoreSection.types';

export const CfsScoreSection = ({ data }: CfsScoreSectionProps) => {
  return (
    <section className="flex flex-col gap-4">
      <SectionTitle title={'CFS Score'} icon={<BarChartIcon />} />

      <div className="grid grid-cols-3 gap-6">
        <ProbabilityOpeningCard
          cfsScore={data['CFS Score']}
          baseScore={data['Median_CFS_Score']}
        />

        <FactorsCard
          data={{
            'Chain Top 1': data['Chain Top 1'],
            'Chain Top 2': data['Chain Top 2'],
            'Chain Top 3': data['Chain Top 3'],
            'Chain Top 4': data['Chain Top 4'],
            'Factor 1': data['Factor 1'],
            'Factor 2': data['Factor 2'],
            'Factor 3': data['Factor 3'],
            'Factor 4': data['Factor 4'],
          }}
        />

        <ProbabilityClosureCard
          data={{
            'Prediction 2 Year': data['Prediction 2 Year'],
            'Prediction 3 Year': data['Prediction 3 Year'],
            'Prediction 4 Year': data['Prediction 4 Year'],
            'Prediction 5 Year': data['Prediction 5 Year'],
          }}
        />
      </div>
    </section>
  );
};
