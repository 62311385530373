import { useCallback } from 'react';
import useAxios from '@services/axios/useAxios';

type IGetResponse = {
  items: any;
  count: number;
};

type IPostResponse = Record<string, any>;

type IGetByIdResponse = {
  item: any;
};

type IGetFileResponse = {
  url: string;
};

type IAxiosController = {
  get: (url: string) => Promise<IGetResponse | null>;
  post: (url: string, data: any) => Promise<IPostResponse | null>;
  getById: (url: string, id?: string) => Promise<IGetByIdResponse | null>;
  getFile: (url: string, path?: string) => Promise<IGetFileResponse | null>;
};

export const AxiosController = (setIsLoading?: (newState: boolean) => void): IAxiosController => {
  const axiosInstance = useAxios({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const get = useCallback(
    async (url: string): Promise<IGetResponse | null> => {
      try {
        if (setIsLoading) setIsLoading(true);

        const response: IGetResponse = await axiosInstance.request({
          url: `${url}`,
        });

        if (!!response && response.items) {
          return response;
        } else {
          throw new Error('Response is NULL');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        return {
          items: [],
          count: 0,
        };
      } finally {
        if (setIsLoading) setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axiosInstance],
  );

  const axiosPostInstance = useAxios({
    method: 'POST',
  });

  const post = useCallback(
    async (url: string, data: any): Promise<IPostResponse | null> => {
      try {
        if (setIsLoading) setIsLoading(true);

        const response: IGetResponse = await axiosPostInstance.request({
          url: `${url}`,
          data: data,
        });

        if (!!response) {
          return response;
        } else {
          throw new Error('Response is NULL');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        return null;
      } finally {
        if (setIsLoading) setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axiosPostInstance],
  );

  const getById = useCallback(
    async (url: string, id?: string): Promise<IGetByIdResponse | null> => {
      try {
        if(!id){
          return {
            item:  null
          }
        }
        if (setIsLoading) setIsLoading(true);

        const response: IGetByIdResponse = await axiosInstance.request({
          url: `${url + '/' + id}`,
        });

        if (!!response) {
          return {
            item: response,
          };
        } else {
          throw new Error('Response is NULL');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        return {
          item: {},
        };
      } finally {
        if (setIsLoading) setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axiosInstance],
  );

  const getFile = useCallback(
    async (path?: string): Promise<IGetFileResponse | null> => {
      try {
        if (setIsLoading) setIsLoading(true);

        const response: IGetFileResponse = await axiosInstance.request({
          url: `${'/files/download?path=' + path}`,
        });

        if (!!response && response.url) {
          return {
            url: response.url,
          };
        } else {
          throw new Error('Response is NULL');
        }
      } catch (error) {
        console.error('Error fetching data:', error);

        return {
          url: '',
        };
      } finally {
        if (setIsLoading) setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axiosInstance],
  );

  return {
    get,
    post,
    getById,
    getFile,
  };
};
